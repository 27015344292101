import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Bench Press 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Weighted Strict Pullups 1-1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><em parentName="p">{`5 Rounds for max reps of:`}</em></p>
    <p><em parentName="p">{`Strict HSPU’s`}</em></p>
    <p><em parentName="p">{`Strict Ring Rows`}</em></p>
    <p><em parentName="p">{`*`}{`rest 2:00 between sets`}</em></p>
    <p><em parentName="p">{`*`}{`compare to 5/7/17`}</em></p>
    <p>{`Bonus Test: Max distance HS Walk, 3 attempts.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      